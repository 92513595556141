$(document).on("turbolinks:load", function () {

  $('.draggable').draggable({
    containment: "parent",
    stop: function( event, ui ) {

      //Get position as a %
      var left = Math.round(ui.position.left / $(this).parent().width() * 100); 
      var top = Math.round(ui.position.top / $(this).parent().height() * 100);

      //Get index of marker
      var markerId = $('.marker').index($(this));
      var marker = $('.nested-fields.markers').eq(markerId)

      //Update cocoon field inputs
      marker.find('.claim_markers_x_coordinate input').val(left);
      marker.find('.claim_markers_y_coordinate input').val(top);
    }
  });

  $('.markers-fields').on('cocoon:before-insert', function(e, insertedItem) {   
    //Get the marker type and side from new field
    var marker = insertedItem.find('.claim_markers_marker_type input').val();
    var side = insertedItem.find('.claim_markers_side input').val();
    insertedItem.addClass('active');

    //Add marker to car img
    var count = $(`.marker.${side}`).length;
    $(`<div class="marker draggable ${marker}" data-id="${count}">
    </div>`).appendTo(`.car-img.${side}`).draggable({
      containment: "parent",
      stop: function( event, ui ) {
  
        //Get position as a %
        var left = Math.round(ui.position.left / $(this).parent().width() * 100); 
        var top = Math.round(ui.position.top / $(this).parent().height() * 100);
  
        //Get index of marker
        var markerId = $(`.marker.${side}`).index($(this));
        var marker = $(`.nested-fields.${side}`).eq(markerId);
  
        //Update cocoon field inputs
        marker.find('.claim_markers_x_coordinate input').val(left);
        marker.find('.claim_markers_y_coordinate input').val(top);
      }
    });
  })

  $('.markers-fields').on('cocoon:before-remove', function(e, deletedItem) {
    var index = $('.nested-fields.markers.active').index(deletedItem);
    $('.car-img.active .draggable').eq(index).hide();
  })

  $('#claim_car_type').on('change', function() {
    var carType = $(this).val();
    $('.car-img').removeClass('sedan truck suv').addClass(carType);
  })

  $('.add_fields[data-association="marker"]')
  .data("association-insertion-method", 'append')
  .data("association-insertion-node", '.markers-fields');

  //Car slides
  var slideIndex = 1;
  var sideArray = ['left', 'front', 'right', 'back']
  showSlides(slideIndex);

  function changeSlide(n) {
    showSlides(slideIndex += n);
  }

  function showSlides(n) {
    var slideCount = $('.car-img').length;
    if (n > slideCount) {
      slideIndex = 1;
    }
    if (n < 1) {
      slideIndex = slideCount;
    }
    var side = sideArray[slideIndex - 1]
    $('.car-img, .links, .markers').removeClass('active');
    $('.car-img').eq(slideIndex - 1).addClass('active');
    $('.links.marker-links').eq(slideIndex - 1).addClass('active');
    $(`.markers.${side}`).addClass('active');
  }

  $(document).on('click', '#next-slide', function(e) {
    e.preventDefault();
    changeSlide(1)
  })

  $(document).on('click', '#prev-slide', function(e) {
    e.preventDefault();
    changeSlide(-1)
  })
})